import { combineReducers } from "redux";
import hotels from "./hotelsReducer";
import cities from "./citiesReducer";
import params from "./paramsReducer";

const rootReducer = combineReducers({
  hotels,
  params,
  cities,
});

export default rootReducer;
