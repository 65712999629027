export default {
  hotels: [],
  cities: [],
  params: {
    checkin_date: null,
    checkout_date: null,
    daycount: null,
    rooms: [],
    hr_links: null,
    city: null,
    district: null,
  },
};
