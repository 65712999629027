import React from "react";
import { connect } from "react-redux";
import * as hotelsActions from "../../redux/actions/hotelsActions";
import * as citiesActions from "../../redux/actions/citiesActions";
import * as paramsActions from "../../redux/actions/paramsActions";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import Select from "react-select";

class DestinationComponent extends React.Component {
  componentDidMount() {
    this.props.hotelactions.loadHotels().catch((error) => {
      console.log("Loading Hotels Failed " + error);
    });
    this.props.citiesactions
      .loadCities()
      .then(() => {
        this.citiesStateInit();
      })
      .catch((error) => {
        console.log("Loading Cities Failed " + error);
      });
  }

  state = {
    selectedCity: "",
    selectedDistrict: "",
    cities: [],
    districts: [],
    hotels: [],
  };

  render() {
    return (
      <section>
        <div className="d-flex justify-content-center">
          <div className="CitiesContainer">
            <div className="row">
              {this.props.cities.map((city) => (
                <div
                  key={city.CITYSLUG}
                  className="col-2  d-flex justify-content-center "
                  onClick={() => this.CityClick(city.CITYSLUG)}
                >
                  <div
                    className={
                      "City align-items-end d-flex " +
                      (this.state.selectedCity === city.CITYSLUG
                        ? "active"
                        : "notactive")
                    }
                    style={{
                      backgroundImage:
                        "url(" +
                        "https://cdn.achrafaamri.com/images/" +
                        city.CITYSLUG +
                        ".jpg" +
                        ")",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="Imagecaption align-items-end  justify-content-center d-flex">
                      <span> {city.CITYNAME}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row text-left CitiesSelectContainer">
          <div className="col ">
            <div className="form-group">
              <label htmlFor="CitySelect">
                <svg
                  className="bi bi-map"
                  width="18px"
                  height="18px"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M15.817.613A.5.5 0 0116 1v13a.5.5 0 01-.402.49l-5 1a.502.502 0 01-.196 0L5.5 14.51l-4.902.98A.5.5 0 010 15V2a.5.5 0 01.402-.49l5-1a.5.5 0 01.196 0l4.902.98 4.902-.98a.5.5 0 01.415.103zM10 2.41l-4-.8v11.98l4 .8V2.41zm1 11.98l4-.8V1.61l-4 .8v11.98zm-6-.8V1.61l-4 .8v11.98l4-.8z"
                    clipRule="evenodd"
                  />
                </svg>{" "}
                Choose a city <span className="text-danger">*</span>
              </label>
              <Select
                styles={customStyles}
                placeholder={"Select City"}
                options={this.state.cities}
                onChange={this.CitySelectClick}
                id="CitySelect"
                aria-describedby="emailHelp"
              />
            </div>
          </div>
        </div>
       
      </section>
    );
  }

  citiesStateInit() {
    this.props.cities.map((city) => {
      this.setState((prevState) => ({
        cities: [
          ...prevState.cities,
          { value: city.CITYSLUG, label: city.CITYNAME },
        ],
      }));
    });
  }

  hotelsStateSet(hotels) {
    this.setState({ hotels: [] });
    let hrlink = [];
    hotels.map((hotel) => {
      this.setState((prevState) => ({
        hotels: [
          ...prevState.hotels,
          { value: hotel.HRLINK, label: hotel.HOTELNAME },
        ],
      }));
      hrlink.push(hotel.HRLINK);
    });
    this.props.paramsactions.updateParams({
      hr_links: hrlink.toString(),
    });
  }

  districtsStateSet(districtsArray) {
    this.setState({ districts: [] });
    districtsArray.map((district) => {
      this.setState((prevState) => ({
        districts: [
          ...prevState.districts,
          { value: district.DISRICTSLUG, label: district.DISRICTNAME },
        ],
      }));
    });
  }
  CityClick(cityslug) {
    this.SelectCity(cityslug);
  }
  CitySelectClick = (selectedOption) => {
    this.SelectCity(selectedOption.value);
  };
  SelectCity(cityslug) {
    let cityList = this.props.cities.filter(
      (city) => city.CITYSLUG == cityslug
    );
    this.props.paramsactions.updateParams({ city: cityslug });
    let hotelList = this.props.hotels.filter(
      (hotel) => hotel.CITYSLUG == cityslug
    );

    this.districtsStateSet(cityList[0].DISRICTS);
    this.hotelsStateSet(hotelList);
    this.setState({ selectedCity: cityslug });
  }
  DistrictSelectClick = (selectedOption) => {
    let hotelList = this.props.hotels.filter(
      (hotel) => hotel.CITYSLUG == this.state.selectedCity
    );
    this.props.paramsactions.updateParams({
      city: this.state.selectedCity,
      district: selectedOption.value,
    });
    if (selectedOption.value === "all") {
      this.hotelsStateSet(hotelList);
    } else {
      hotelList.filter((hotel) => hotel.DISTRICTSLUG == selectedOption.value);
      this.hotelsStateSet(hotelList);
    }
  };
  HotelSelectClick = (selectedOption) => {
    this.props.paramsactions.updateParams({
      hr_links: selectedOption.value,
      city: this.state.selectedCity,
      district: this.state.selectedDistrict,
    });
  };
}
const customStyles = {
  control: (base) => ({
    ...base,
    height: "53px",
    minHeight: "53px",
    boxShadow: "0 8px 16px rgba(94, 82, 246, 0.25)",
    transition: "all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)",
    borderRadius: "5px",
  }),
};
function mapStateToProps(state) {
  return {
    hotels: state.hotels,
    cities: state.cities,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    hotelactions: bindActionCreators(hotelsActions, dispatch),
    citiesactions: bindActionCreators(citiesActions, dispatch),
    paramsactions: bindActionCreators(paramsActions, dispatch),
  };
}
DestinationComponent.propTypes = {
  hotels: PropTypes.array.isRequired,
  hotelactions: PropTypes.object.isRequired,
  cities: PropTypes.array.isRequired,
  citiesactions: PropTypes.object.isRequired,
  paramsactions: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DestinationComponent);
