import React from "react";
import OccupationComponent from "./occupation/OccupationComponent";
import DestinationComponent from "./destination/DestinationComponent";
import { connect } from "react-redux";
import * as paramsActions from "../redux/actions/paramsActions";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";

class MainComponent extends React.Component {
  state = {
    citychosen: true,
    datechosen: true,
    listhotels: [],
  };

  GetAvails = () => {
    if (this.props.searchParams.params.city != null) {
      this.setState(() => ({
        citychosen: true,
      }));

      if (
        this.props.searchParams.params.checkin_date != null &&
        this.props.searchParams.params.checkout_date != null
      ) {
        this.setState(() => ({
          datechosen: true,
        }));

        let queryurlstring =
          "api_key=7518391247a27ce412d421ffe241c6ffd3f52e7c4b26e993&checkin_date=" +
          this.props.searchParams.params.checkin_date +
          "&checkout_date=" +
          this.props.searchParams.params.checkout_date +
          "&day_count=" +
          this.props.searchParams.params.daycount +
          "&room_count=" +
          this.props.searchParams.params.rooms.length;

        let paramquery = {
          checkin_date: this.props.searchParams.params.checkin_date,
          checkout_date: this.props.searchParams.params.checkout_date,
          day_count: this.props.searchParams.params.daycount,
          room_count: this.props.searchParams.params.rooms.length,
          total_adult: 0,
          total_child: 0,
          rooms: [],
          guest_rooms: {},
        };
        let queryroomsetup = "";
        let queryroomguestsetup = "";

        this.props.searchParams.params.rooms.map((room, index) => {
          let childages = [];
          if (room.child_ages != "") {
            childages = room.child_ages.split(",");
          }
          let guest_count = 0;

          paramquery.total_adult += Number(room.adult_count);
          paramquery.total_child += childages.length;
          paramquery.rooms.push({
            adult_count: room.adult_count,
            guest_count: Number(room.adult_count) + childages.length,
            child_count: childages.length,
            child_ages: childages,
          });
          let childagequery = "";
          let childageguestquery = "";
          childages.map((childage) => {
            childagequery = childagequery + "&rooms[][child_ages]=" + childage;
            childageguestquery =
              childageguestquery +
              "&guest_rooms[" +
              index +
              "][child_ages]=" +
              childage;
          });
          guest_count = Number(room.adult_count) + childages.length;
          queryroomsetup =
            queryroomsetup +
            "&rooms[][adult_count]=" +
            room.adult_count +
            "&rooms[][guest_count]=" +
            guest_count +
            "&rooms[][child_count]=" +
            childages.length +
            childagequery;

          queryroomguestsetup =
            queryroomguestsetup +
            "&guest_rooms[" +
            index +
            "][adult_count]=" +
            room.adult_count +
            "&guest_rooms[" +
            index +
            "][guest_count]=" +
            guest_count +
            "&guest_rooms[" +
            index +
            "][child_count]=" +
            childages.length +
            childageguestquery;
        });
        queryurlstring =
          queryurlstring +
          "&total_adult=" +
          paramquery.total_adult +
          "&total_child=" +
          paramquery.total_child +
          queryroomsetup +
          queryroomguestsetup +
          "&currency=MAD&locale=fr-FR";

        paramquery.guest_rooms = Object.assign({}, paramquery.rooms);

        let url =
          ".hotelrunner.com/api/v1/bv3/search/availabilities.json?" +
          queryurlstring;
        this.getHotelsAvails(url, paramquery);
      } else {
        this.setState(() => ({
          datechosen: false,
          link: "#",
        }));
      }
    } else {
      if (
        this.props.searchParams.params.checkin_date != null &&
        this.props.searchParams.params.checkout_date != null
      ) {
        this.setState(() => ({
          datechosen: true,
          citychosen: false,
        }));
      } else {
        this.setState(() => ({
          datechosen: false,
          citychosen: false,
        }));
      }
    }
  };

  getHotelsAvails = (paramquery, hrquery) => {
    let HRLINKS = this.props.searchParams.params.hr_links.split(",");

    let filteredArray = this.props.hotels.filter(function (hotel) {
      return HRLINKS.includes(hotel.HRLINK);
    });
    let hotellist = [];
    filteredArray.map((hotel) => {
      let newhotel = {
        hotelname: hotel.HOTELNAME,
        hotelslug: hotel.HRLINK,
        availstatus: 0,
        link:
          "https://" +
          hotel.HRLINK +
          ".hotelrunner.com/bv3/search?search=" +
          encodeURIComponent(JSON.stringify(hrquery)),
      };
      hotellist.push(newhotel);
    });
    this.setState(() => ({
      listhotels: hotellist,
    }));

    hotellist.map((hotel) => {
      try {
        fetch("https://" + hotel.hotelslug + paramquery)
          .then((resp) => resp.json())
          .then((data) => {
            if (data.available_room_types.length > 0) {
              hotel.availstatus = 2;
            } else {
              hotel.availstatus = 1;
            }
            this.setState(() => ({
              listhotels: hotellist,
            }));
          });
      } catch (err) {
        console.log(err); // Failed to fetch
        hotel.availstatus = 1;
        this.setState(() => ({
          listhotels: hotellist,
        }));
      }
    });
  };

  render() {
    return (
      <div className="container text-center">
        <DestinationComponent />
        <OccupationComponent />
        <div className="mrg-b-10">
          <small
            className={
              "form-text" +
              (this.state.citychosen ? " d-none " : " text-danger")
            }
          >
            Please Select a City to proceed
          </small>{" "}
          <small
            className={
              "form-text" +
              (this.state.datechosen ? " d-none " : " text-danger")
            }
          >
            Please Select a Date to proceed
          </small>
        </div>

        <div className="row">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-primary  btn-block"
              onClick={this.GetAvails}
            >
              Check Availibility
            </button>
          </div>
        </div>
        <div className="availarea     ">
          {this.state.listhotels.map((hotel, index) => (
            <div className="row property" key={index}>
              <div
                className="col-md-3 propertyimage"
                style={{
                  backgroundImage: "url(https://via.placeholder.com/150)",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                {hotel.hotelslug}
              </div>
              <div className="col-md-6  text-left">
                <h4 className="text-info">{hotel.hotelname}</h4>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ea
                  facere laboriosam suscipit repellat corrupti aspernatur
                </p>
              </div>
              <div className="col-md-3  d-flex align-items-center justify-content-center">
                <a target="_blank" rel="noopener noreferrer" href={hotel.link}>
                  <button
                    className={
                      hotel.availstatus != 2
                        ? " d-none "
                        : "btn btn-success mrg-b-10 "
                    }
                  >
                    Choose your room
                  </button>
                </a>

                <p
                  className={
                    hotel.availstatus != 1
                      ? " d-none "
                      : "text-center text-danger"
                  }
                >
                  <strong> not available</strong>
                </p>
                <button
                  className={
                    hotel.availstatus != 0
                      ? " d-none "
                      : "btn btn-success mrg-b-10 "
                  }
                >
                  Choose your room
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    searchParams: state.params,
    hotels: state.hotels,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(paramsActions, dispatch),
  };
}
MainComponent.propTypes = {
  hotels: PropTypes.array.isRequired,
  searchParams: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainComponent);
