import * as types from "./actionTypes";
import * as DataApi from "../../api/dataApi";

export function loadcitiessSuccess(cities) {
  return { type: types.LOAD_CITIES_SUCCESS, cities };
}

export function loadCities() {
  return function (dispatch) {
    return DataApi.getCities()
      .then((cities) => {
        dispatch(loadcitiessSuccess(cities));
      })
      .catch((error) => {
        throw error;
      });
  };
}
