import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function paramsReducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_PARAMS:
      return { ...state, params: { ...state.params, ...action.Params } };
    //    return action.updateParams;
    default:
      return state;
  }
}
