import { handleResponse, handleError } from "./apiUtils";
const baseUrl = "https://resa.hospitalitywebservices.com/DataFiles/";

export function getHotels() {
  return fetch(baseUrl + "Hotels.php")
    .then(handleResponse)
    .catch(handleError);
}
export function getCities() {
  return fetch(baseUrl + "Cities.php")
    .then(handleResponse)
    .catch(handleError);
}
