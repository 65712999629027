import React from "react";
import { connect } from "react-redux";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import Select from "react-select";
import "react-dates/lib/css/_datepicker.css";
import * as paramsActions from "../../redux/actions/paramsActions";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";

import Responsive from "react-responsive";
export const Mobile = (props) => <Responsive {...props} maxWidth={767} />;
export const Default = (props) => <Responsive {...props} minWidth={768} />;
class OccupationComponent extends React.Component {
  componentDidMount() {
    let newroom = {
      id: Math.floor(Math.random() * 10000000),
      adult_count: 2,
      child_ages: [],
    };
    this.setState((prevstate) => ({
      rooms: [...prevstate.rooms, newroom],
      adultsOptions: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
      ],
      ChildAgesOptions: [
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
      ],
    }));

    this.props.paramsactions.updateParams({
      rooms: [
        {
          adult_count: 2,
          guest_count: 2,
          child_count: 0,
          child_ages: "",
        },
      ],
    });
  }

  state = {
    startDate: null,
    endDate: null,
    showOccupancy: false,
    showDelete: false,
    rooms: [],
    temporaryrooms: [],
    adultsOptions: [],
    roomCount: 1,
    adultCount: 2,
    childCount: 0,
  };
  hundleDateChange(startDate, endDate) {
    this.setState(() => ({
      endDate,
      startDate,
    }));
    if (startDate != null) {
      this.props.paramsactions.updateParams({
        checkin_date: startDate.format("Y-MM-D"),
      });
    }
    if (endDate != null) {
      this.props.paramsactions.updateParams({
        checkout_date: endDate.format("Y-MM-D"),
        daycount: endDate.diff(startDate, "days"),
      });
    }
  }
  toggleOccupancy = () => {
    this.setState((prevstate) => ({
      temporaryrooms: prevstate.rooms,
    }));

    this.setState((prevstate) => ({
      showOccupancy: !prevstate.showOccupancy,
    }));
  };
  adultsOptionSelect = (selectedOption, index) => {
    let data = this.state.temporaryrooms;
    data[index].adult_count = selectedOption.value;
    this.setState({
      temporaryrooms: data,
    });
  };
  ChildsOptionSelect = (selectedOption, roomindex, childindex) => {
    let data = this.state.temporaryrooms;
    data[roomindex].child_ages[childindex].age = selectedOption.value;
    this.setState({
      temporaryrooms: data,
    });
  };
  deleteChildAge(roomindex, childindex) {
    let data = this.state.temporaryrooms;
    data[roomindex].child_ages.splice(childindex, 1);
    console.log(childindex);
    this.setState({
      temporaryrooms: data,
    });
  }

  addRoom = () => {
    let newroom = {
      id: Math.floor(Math.random() * 10000000),
      adult_count: 2,
      child_ages: [],
    };
    this.setState((prevstate) => ({
      temporaryrooms: [...prevstate.temporaryrooms, newroom],
    }));
  };
  deleteRoom = (index) => {
    let data = this.state.temporaryrooms;
    data.splice(index, 1);
    this.setState((prevstate) => ({
      temporaryrooms: data,
      roomCount: prevstate.roomCount - 1,
    }));
  };

  pushRooms = () => {
    let childcount = 0;
    let adultcount = 0;
    let roomsArray = [];
    this.state.temporaryrooms.map((room) => {
      adultcount += Number(room.adult_count);
      childcount += room.child_ages.length;
      let childages = [];
      room.child_ages.map((childage) => {
        childages.push(childage.age);
      });
      roomsArray.push({
        adult_count: room.adult_count,
        guest_count: Number(room.adult_count) + room.child_ages.length,
        child_count: room.child_ages.length,
        child_ages: childages.toString(),
      });
    });

    this.setState((prevstate) => ({
      rooms: prevstate.temporaryrooms,
      roomCount: prevstate.temporaryrooms.length,
      adultCount: adultcount,
      childCount: childcount,
    }));
    this.props.paramsactions.updateParams({
      rooms: roomsArray,
    });
    this.toggleOccupancy();
  };
  AddChildToRoom = (index) => {
    //get childages table
    let newchildage = {
      id: Math.floor(Math.random() * 10000000),
      age: "1",
    };
    let data = this.state.temporaryrooms;
    data[index].child_ages = [...data[index].child_ages, newchildage];
    this.setState({
      rooms: data,
    });
  };

  render() {
    return (
      <div className="row  justify-content-end">
        <div className="col-md-6">
          <Mobile>
            <div className="customDatePickerWidth">
              <DateRangePicker
                startDate={this.state.startDate}
                startDateId="start_date_id"
                endDate={this.state.endDate}
                endDateId="end_date_id"
                onDatesChange={({ startDate, endDate }) =>
                  this.hundleDateChange(startDate, endDate)
                }
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                }
                block
                numberOfMonths={1}
                orientation="horizontal"
              />
            </div>
          </Mobile>
          <Default>
            <div className="customDatePickerWidth">
              <DateRangePicker
                startDate={this.state.startDate}
                startDateId="start_date_id"
                endDate={this.state.endDate}
                endDateId="end_date_id"
                onDatesChange={({ startDate, endDate }) =>
                  this.hundleDateChange(startDate, endDate)
                }
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                }
                block
                numberOfMonths={2}
                orientation="horizontal"
              />
            </div>
          </Default>
        </div>
        <div className="col-md-6">
          <table className="table table-hover  table-striped table-bordered">
            <thead onClick={this.toggleOccupancy}>
              <tr>
                <th scope="col"> {this.state.roomCount} Rooms </th>
                <th scope="col"> {this.state.adultCount} Adults</th>
                <th scope="col"> {this.state.childCount} Childs</th>
              </tr>
            </thead>
            <tbody
              className={this.state.showOccupancy ? "active" : "notactive"}
            >
              {this.state.temporaryrooms.map((room, roomindex) => (
                <tr key={room.id}>
                  <th scope="row">
                    <svg
                      onClick={() => this.deleteRoom(roomindex)}
                      className={
                        "bi bi-trash align-left " +
                        (this.state.roomCount < 2 ? "displaynone" : "")
                      }
                      width="18px"
                      height="18px"
                      viewBox="0 0 16 16"
                      fill="red"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5.5 5.5A.5.5 0 016 6v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm2.5 0a.5.5 0 01.5.5v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm3 .5a.5.5 0 00-1 0v6a.5.5 0 001 0V6z" />
                      <path
                        fillRule="evenodd"
                        d="M14.5 3a1 1 0 01-1 1H13v9a2 2 0 01-2 2H5a2 2 0 01-2-2V4h-.5a1 1 0 01-1-1V2a1 1 0 011-1H6a1 1 0 011-1h2a1 1 0 011 1h3.5a1 1 0 011 1v1zM4.118 4L4 4.059V13a1 1 0 001 1h6a1 1 0 001-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                        clipRule="evenodd"
                      />
                    </svg>
                    # Room {roomindex + 1}
                  </th>
                  <td>
                    <p>Adults :</p>
                    <Select
                      placeholder={"Select Adults"}
                      components={{ DropdownIndicator: () => null }}
                      value={{
                        label: room.adult_count,
                        value: room.adult_count,
                      }}
                      options={this.state.adultsOptions}
                      onChange={(ev) => this.adultsOptionSelect(ev, roomindex)}
                    />
                  </td>
                  <td>
                    <p>Childs {room.child_ages.length} :</p>

                    {room.child_ages.map((childage, childindex) => (
                      <div key={childindex} className="row">
                        <div className="col-6 align-self-center">
                          <svg
                            onClick={() =>
                              this.deleteChildAge(roomindex, childindex)
                            }
                            className="bi bi-trash align-left "
                            width="13px"
                            height="13px"
                            viewBox="0 0 16 16"
                            fill="red"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5.5 5.5A.5.5 0 016 6v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm2.5 0a.5.5 0 01.5.5v6a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm3 .5a.5.5 0 00-1 0v6a.5.5 0 001 0V6z" />
                            <path
                              fillRule="evenodd"
                              d="M14.5 3a1 1 0 01-1 1H13v9a2 2 0 01-2 2H5a2 2 0 01-2-2V4h-.5a1 1 0 01-1-1V2a1 1 0 011-1H6a1 1 0 011-1h2a1 1 0 011 1h3.5a1 1 0 011 1v1zM4.118 4L4 4.059V13a1 1 0 001 1h6a1 1 0 001-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Child # {childindex + 1}
                        </div>
                        <div className="col-6">
                          <Select
                            placeholder={"Child Age"}
                            onChange={(ev) =>
                              this.ChildsOptionSelect(ev, roomindex, childindex)
                            }
                            options={this.state.ChildAgesOptions}
                            value={{ label: childage.age, value: childage.age }}
                            components={{ DropdownIndicator: () => null }}
                          />
                        </div>
                      </div>
                    ))}

                    <button
                      type="button"
                      onClick={() => this.AddChildToRoom(roomindex)}
                      className="btn btn-outline-primary btn-sm"
                    >
                      + Add A Child
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot
              className={this.state.showOccupancy ? "active" : "notactive"}
            >
              <tr>
                <td colSpan="2">
                  <button
                    onClick={this.addRoom}
                    type="button"
                    className="btn btn-outline-primary btn-block"
                  >
                    + Add A Room
                  </button>
                </td>
                <td>
                  <button
                    onClick={this.pushRooms}
                    type="button"
                    className="btn btn-outline-success  btn-block"
                  >
                    comfirm
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    hotels: state.hotels,
    cities: state.cities,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    paramsactions: bindActionCreators(paramsActions, dispatch),
  };
}
OccupationComponent.propTypes = {
  paramsactions: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OccupationComponent);
