import * as types from "./actionTypes";
import * as DataApi from "../../api/dataApi";

export function loadhotelsSuccess(hotels) {
  return { type: types.LOAD_HOTELS_SUCCESS, hotels };
}

export function loadHotels() {
  return function (dispatch) {
    return DataApi.getHotels()
      .then((hotels) => {
        dispatch(loadhotelsSuccess(hotels));
      })
      .catch((error) => {
        throw error;
      });
  };
}
